import React from 'react';
import InputCheck from './InputCheck';

function InputsCheckboxDay({ cleanValue }) {

  const inputs = [
    { value: "понедельник", trim: "Пн" },
    { value: "вторник", trim: "Вт" },
    { value: "среда", trim: "Ср" },
    { value: "четверг", trim: "Чт" },
    { value: "пятница", trim: "Пт" },
    { value: "суббота", trim: "Сб" },
    { value: "воскресенье", trim: "Вс" },
  ]

  return (
    <div className="input-checkbox">
      <div className="select-day">Дни</div>
      <div className="input-box">
        {inputs.map((input, i) =>
          <InputCheck
            id={i}
            value={input.value}
            nameLabel={input.trim}
            key={input.trim + i}
            cleanValue={cleanValue}
          />
        )}
      </div>
    </div>
  );
}

export default InputsCheckboxDay;