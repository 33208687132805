import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchTicket = createAsyncThunk(
  'ticket/fetchTicket',
  async function (_,{ rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket/`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const arr = []
      for (const key in data) {
        const newObject = {
          idx: data[key].id,
          schedule: data[key].schedule,
          ...JSON.parse(data[key].ticket)
        }
        arr.push(newObject)
      }
      return arr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const fetchTicketOne = createAsyncThunk(
  'ticket/fetchTicketOne',
  async function (idx, { rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket/${idx}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const arr = []
      for (const key in data) {
        const newObject = {
          idx: data[key].id,
          schedule: data[key].schedule,
          ...JSON.parse(data[key].ticket)
        }
        arr.push(newObject)
      }
      return arr[0]

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const creatTicket = createAsyncThunk(
  'ticket/creatTicket',
  async function ({ tickets, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'ticket': tickets, 'id': idx })
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      dispatch(addTicket(JSON.parse(data.data.ticket)))

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const removeBooking = createAsyncThunk(
  'ticket/removeBooking',
  async function (idx, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket/${idx}`, {
        method: 'DELETE',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');

      dispatch(deleteBooking(idx))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const removeTicket = createAsyncThunk(
  'ticket/removeTicket',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket/${id}`, {
        method: 'DELETE',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');

      dispatch(deleteTicket(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editTicket = createAsyncThunk(
  'ticket/editTicket',
  async function ({ object, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/ticket/${idx}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(object),
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      const newObject = {
        idx: data.data.id,
        schedule: data.data.schedule,
        ...JSON.parse(data.data.ticket)
      }
      
      dispatch(modifyTicket(newObject))

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}
const ticketSlice = createSlice({
  name: 'tickets',
  initialState: {
    tickets: [],
    ticket: '',
    status: null,
    error: null,
  },
  reducers: {
    cleanTicket(state) {
      state.ticket = {}
    },
    addTicket(state, action) {
      state.ticket = action.payload
    },
    deleteBooking(state, action) {
      state.tickets = state.tickets.filter(ticket => ticket.idx !== action.payload)
      success.fire({
        text: "Бронь удалена!",
      });
    },
    deleteTicket(state, action) {
      state.ticket = action.payload
      success.fire({
        text: "Билет удален!",
      });
    },
    modifyTicket(state, action) {
      state.ticket = action.payload
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchTicket.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchTicketOne.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [creatTicket.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchTicket.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.tickets = action.payload
    },
    [fetchTicketOne.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.ticket = action.payload
    },
    [editTicket.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchTicket.rejected]: setError,
    [fetchTicketOne.rejected]: setError,
    [removeBooking.rejected]: setError,
    [removeTicket.rejected]: setError,
    [editTicket.rejected]: setError,
  }
})
export const { addTicket, deleteBooking, cleanTicket, deleteTicket, modifyTicket} = ticketSlice.actions
export default ticketSlice.reducer