import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchTrail = createAsyncThunk(
  'trails/fetchTrail',
  async function (_, { rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/trail`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()

      const arr = []
      for (const key in data) {
        const newObject = {
          id: data[key].id,
          ...JSON.parse(data[key].trail)
        }
        arr.push(newObject)
      }

      return arr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const fetchOneTrail = createAsyncThunk(
  'trails/fetchOneTrail',
  async function (idx, { rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/trail/${idx}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      const newObject = {
        id: data.id,
        ...JSON.parse(data.trail)
      }
      return newObject

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const creatTrail = createAsyncThunk(
  'trails/creatTrail',
  async function (text, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/trail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(text)
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      const id = {
        id: data.data.id,
        ...JSON.parse(data.data.trail)
      }
      dispatch(addTrail(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const removeTrail = createAsyncThunk(
  'trails/removeTrail',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/trail/${id}`, {
        method: 'DELETE',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');

      dispatch(deleteTrail(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editTrail = createAsyncThunk(
  'trails/editTrail',
  async function ({ object, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/trail/${idx}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(object),
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()

      const id = {
        id: data.data.id,
        ...JSON.parse(data.data.trail)
      }
      dispatch(modifyTrail(id))
      const form = document.getElementById(idx)
      const button = form.querySelector('button')
      button.setAttribute('disabled', 'disabled')
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const trailSlice = createSlice({
  name: 'trails',
  initialState: {
    trails: [],
    trail: [],
    status: null,
    error: null,
  },
  reducers: {
    addTrail(state, action) {
      state.trails.push(action.payload)
    },
    deleteTrail(state, action) {
      state.trails = state.trails.filter(trail => trail.id !== action.payload)
      success.fire({
        text: "Маршрут удален!",
      });
    },
    modifyTrail(state, action) {
      state.trails = state.trails.filter(trail => trail.id !== action.payload.id)
      state.trails.push(action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchTrail.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [fetchOneTrail.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [creatTrail.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editTrail.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchTrail.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.trails = action.payload
    },
    [fetchOneTrail.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.trail = action.payload
    },
    [fetchTrail.rejected]: setError,
    [fetchOneTrail.rejected]: setError,
    [creatTrail.rejected]: setError,
    [editTrail.rejected]: setError,
    [removeTrail.rejected]: setError,
  }
})
const { addTrail, deleteTrail, modifyTrail } = trailSlice.actions
export default trailSlice.reducer