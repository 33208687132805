import React, { useState } from "react";
import Menu from '../components/Menu/Menu';

import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';
import BookingForm from '../components/BookingForm/BookingForm'
const inputStyle = {
  maxWidth: '100%'
}
function TrailIdPage() {
  const { status, error } = useSelector(state => state.tickets)
  const [val, setVal] = useState('')
  return (
    <section className="pages">
      <Menu />
      <Container>
        <h1>БРОНЬ</h1>
        <div className="input-style">
          <label>Поиск брони</label>
          <input style={inputStyle} type="text" onChange={(e) => setVal(e.target.value)} />
        </div >
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <h2>Loading...</h2>}
        {error && <h2>{error}</h2>}
        <BookingForm search={val} />
      </Container>
    </section >
  );
};

export default TrailIdPage;