import React, { useEffect, useRef, useState } from 'react';
import Input from '../UI/Input';
import InputMask from 'react-input-mask';
import SelectSearch from '../UI/SelectSearch/SelectSearch'
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../AddTicketForm/TicketForm.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOptions } from '../../store/optionsSlice'
import { fetchSchedule } from '../../store/scheduleSlice'
const inputPhoneStyle = {
  inputPhoneBox: {
    display: 'flex',
    alignItems: 'center'
  },
  p: {
    margin: '0 5px 0 0'
  },
  inputPhone: {
    marginLeft: '8px'
  },
  phoneCode: {
    width: 'auto',
  }

}

const calendarOptions = {
  autoClose: true,
  view: "days",
  minView: "months",
  dateFormat: "dd.MM.yyyy",
  multipleDatesSeparator: " - ",
  buttons: ['clear'],
}

function BookingTicketForm({ idx, tickets, removeTicket, schedule, idTicket, childrenWatch }) {
  const dispatch = useDispatch()
  const { childrensales } = useSelector(state => state.options.options)
  const schedules = useSelector(state => state.schedules.schedules)

  useEffect(() => {
    dispatch(fetchOptions())
    dispatch(fetchSchedule())
  }, [dispatch])
  const [val, setVal] = useState('+375');

  function getSelect({ value }) {
    setVal(value)
  }

  const [newPrice, setNewPrice] = useState('');

  const [priceSchedule, setPriceSchedule] = useState(0);
  const childrenInput = useRef('')

  useEffect(() => {
    const sched = +schedules.filter(el => el.id === schedule).map(el => el.price).join()
    setNewPrice(sched)
    setPriceSchedule(sched);

    if (childrenInput.current.checked) {
      const cost = sched - sched * Number(childrensales) / 100
      setNewPrice(cost)
    }
  }, [schedules, childrenInput])

  useEffect(() => {
    const sched = +schedules.filter(el => el.id === schedule).map(el => el.price).join()
    setPriceSchedule(sched);

    if (childrenInput.current.checked) {
      const cost = sched - sched * Number(childrensales) / 100
      setNewPrice(cost)
      childrenWatch(Math.floor(10000000 + Math.random() * 90000000))
    }
  }, [schedules, childrenInput])

  function childrenSales(params) {
    if (params.checked) {
      const cost = priceSchedule - priceSchedule * Number(childrensales) / 100
      setNewPrice(cost);
    } else {
      setNewPrice(priceSchedule);
    }
    childrenWatch(Math.floor(10000000 + Math.random() * 90000000))
  }


  return (
    <Row className={styles.AddTicket + ' ' + 'add-booking ticket-form'} id={'ticket-form-' + idx}>
      <div>
        <div className={styles.tickettitle}>
          <span className={styles.closebutton} onClick={() => removeTicket(idTicket)}>&times;</span>
          <p>Билет: {idx + 1}</p>
        </div>
        <label className={styles.inputCheck} htmlFor={"children-" + idx}>
          <span>Детский</span>
          <input ref={childrenInput} id={"children-" + idx} type="checkbox" name={'Возраст'} value="Детский" defaultChecked={tickets['Возраст'] && true} onChange={(e) => childrenSales(e.target)} />
        </label>
      </div>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Имя" name={'Имя'} value={tickets['Имя']} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Отчество" name={'Отчество'} value={tickets['Отчество']} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Фамилия" name={'Фамилия'} value={tickets['Фамилия']} />
      </Col>
      <Col lg="3" className='mb-3 mt-3'>
        <div className="input-style">
          <label>Тип документа</label>
          <SelectSearch placeholder="Выбрать..." defaultValue={{ name: 'Тип_документа', value: tickets['Тип_документа'] }} options={[{ value: 'Паспорт', name: 'Тип_документа' }, { value: 'Вид на жительство', name: 'Тип_документа' }, { value: 'Другой документ', name: 'Тип_документа' }]} />
        </div>
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Номер документа" name={'Номер_документа'} value={tickets['Номер_документа']} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input calendarOptions={calendarOptions} className={'calendar' + idx} title="Дата рождения" name={'Дата_рождения'} value={tickets['Дата_рождения']} />
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <div className="input-style input-select">
          <label>Пол</label>
          <SelectSearch placeholder="Выбрать..." defaultValue={{ name: 'Пол', value: tickets['Пол'] }} options={[{ value: 'Мужской', name: 'Пол' }, { value: 'Женский', name: 'Пол' }]} />
        </div>
      </Col>
      <Col lg="2" className='mb-3 mt-3'>
        <Input title="Гражданство" name={'Гражданство'} value={tickets['Гражданство']} />
      </Col>
      <Col lg="3" className='mb-3 mt-3'>
        <div className="input-style">
          <label>Телефон</label>
          <div style={inputPhoneStyle.inputPhoneBox} className="input-phone-box">
            <p style={inputPhoneStyle.p}>+</p>
            <SelectSearch defaultValue={{ name: 'Код', value: tickets['Код'] ? tickets['Код'] : val }} onChange={getSelect}
              options={[{ value: '+375', name: 'Код' }, { value: '+7', name: 'Код' }]}
            />
            {val === '+375'
              ? <InputMask name={'Телефон'} style={inputPhoneStyle.inputPhone} mask="(99) 999 99 99" maskChar="_" placeholder="(__) ___ __ __" key="1" defaultValue={tickets['Телефон']} />
              : <InputMask name={'Телефон'} style={inputPhoneStyle.inputPhone} mask="(999) 999 99 99" maskChar="_" placeholder="(___) ___ __ __" key="2" defaultValue={tickets['Телефон']} />
            }

          </div>
        </div>
      </Col>
      <Col lg="3" className={'mb-3 mt-3 ' + styles.pricebox} >
        <div className={styles.price}>
          <input name="price" readOnly className={styles.pricefield + ' price-ticket'} value={`Цена: ${newPrice} BYN`} />
        </div>
      </Col>
      <Col lg="6" className='mb-3 mt-0 but-box'>
      </Col>
    </Row>
  );
}

export default BookingTicketForm 