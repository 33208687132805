import React, { useState } from 'react';
import Input from '../UI/Input';
import SelectDay from '../UI/SelectDay';

import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useDispatch } from 'react-redux';
import { creatTrail } from '../../store/trailSlice'

import './TrailFormsStyle.sass';

import { alertErr, success } from '../UI/alert'

const calendarOptions = {
  autoClose: true,
  view: "days",
  minView: "months",
  dateFormat: "dd.MM.yyyy",
  multipleDatesSeparator: " - ",
  buttons: ['clear'],
}

function CreateTrailForm() {
  const [flag, setFlag] = useState(false)

  const dispatch = useDispatch()

  const sendCreatTrail = (e) => {

    e.preventDefault()

    const formData = new FormData(e.target)
    const object = {
      day: []
    }

    formData.forEach(function (value, key) {
      if (key === 'day') object.day.push(value)
      else object[key] = value
    });

    if (!object.name) {
      alertErr.fire({
        text: 'Введите названия маршрута!',
      })
      return
    }
    if (!object.start) {
      alertErr.fire({
        text: 'Укажите дату начала!',
      })
      return
    }
    if (!object.finish) {
      alertErr.fire({
        text: 'Укажите дату конца!',
      })
      return
    }
    if (!object.day.length) {
      alertErr.fire({
        text: 'Укажите дни недели!',
      })
      return
    }

    dispatch(creatTrail(object))
    success.fire({
      text: "Маршрут добавлен!",
    });

    setFlag(true)
    setTimeout(() => {
      setFlag(false)
    }, 500);

  }

  return (
    <Form className="add-trail" onSubmit={sendCreatTrail}>
      <Row>
        <Col lg="6">
          <Row className="add-trail-row">
            <Col lg="12" className='mb-0 mt-4'>
              <Input title="Название маршрута" name="name" cleanValue={flag} />
            </Col>
            <Col lg="6" className='mb-0 mt-4'>
              <Input calendarOptions={calendarOptions} className={'calendar-1'} title="Открытие маршрута" name="start" cleanValue={flag} />
            </Col>
            <Col lg="6" className='mb-0 mt-4'>
              <Input calendarOptions={calendarOptions} className={'calendar-2'} title="Закрытие маршрута" name="finish" cleanValue={flag} />
            </Col>
            <Col lg="6" className='mb-3 mt-4'>
              <SelectDay cleanValue={flag} />
            </Col>
            <Col lg="6" className='mb-3 mt-0 but-box'>
              <Button variant="primary" type="submit"> ДОБАВИТЬ МАРШРУТ </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export { CreateTrailForm }
