import React, { useEffect, useRef, useState } from "react";

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

export default function Input({ style, calendarOptions, title, name, value = '', cleanValue, className = '', type = "text" }) {

  const [val, setVal] = useState('');
  const [readonly, setReadonly] = useState('');

  const inputRef = useRef('')

  useEffect(() => {
    setVal(value)
  }, [value])

  useEffect(() => {
    if (cleanValue) inputRef.current.value = ''
  }, [cleanValue])

  useEffect(() => {
    if (className) setReadonly('readonly')
    if (className && calendarOptions) new AirDatepicker('.' + className, calendarOptions)
  }, [])

  return (
    <div className="input-style" style={style}>
      <label>{title}</label>
      <input ref={inputRef} readOnly={readonly} autoComplete="off" className={className} type={type} name={name} defaultValue={val} onChange={(e) => { setVal(e.target.value) }} />
    </div>
  )
}