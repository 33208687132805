import React from 'react';
import InputCheck from './InputCheck';
function InputsCheckboxDay({ days, idx }) {
  let day = []
  if (days && days.length) day = days

  let allday = [
    { name: 'понедельник', trim: "Пн", check: false },
    { name: 'вторник', trim: "Вт", check: false },
    { name: 'среда', trim: "Ср", check: false },
    { name: 'четверг', trim: "Чт", check: false },
    { name: 'пятница', trim: "Пт", check: false },
    { name: 'суббота', trim: "Сб", check: false },
    { name: 'воскресенье', trim: "Вс", check: false },
  ]
  for (let key of allday) {
    for (let d of day) {
      if (d === key.name) key.check = true
    }
  }
  return (
    <div className="input-checkbox">
      <div className="select-day">Дни</div>
      <div className="input-box">
        {allday.map((input, i) =>
          <InputCheck
            id={idx + '-' + i}
            value={input.name}
            nameLabel={input.trim}
            key={input.trim + i}
            check={input.check}
          />
        )}
      </div>
    </div>
  );
}

export default InputsCheckboxDay;