import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchSchedule = createAsyncThunk(
  'schedule/fetchSchedule',
  async function (idx, { rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/schedule/${idx}`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()

      const arr = []
      for (const key in data) {
        const newObject = {
          id: data[key].id,
          ...JSON.parse(data[key].schedule)
        }
        arr.push(newObject)
      }

      return arr

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const creatSchedule = createAsyncThunk(
  'schedule/creatSchedule',
  async function ({ object, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/schedule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'schedule': object, 'id': idx })
      })
      if (!response.ok) throw new Error('Can\'t create task. Server Error!');
      const data = await response.json()

      const id = {
        id: data.data.id,
        ...JSON.parse(data.data.schedule)
      }

      dispatch(addSchedule(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось создать.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const removeSchedule = createAsyncThunk(
  'schedule/removeSchedule',
  async function (id, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/schedule/${id}`, {
        method: 'DELETE',
      })
      if (!response.ok) throw new Error('Can\'t remove task. Server Error!');

      dispatch(deleteSchedule(id))
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось удалить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
export const editSchedule = createAsyncThunk(
  'schedule/editSchedule',
  async function ({ object, idx }, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/schedule/${idx}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(object),
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()

      const id = {
        id: data.data.id,
        ...JSON.parse(data.data.schedule)
      }
      dispatch(modifySchedule(id))
      const form = document.getElementById(idx)
      const button = form.querySelector('button')
      button.setAttribute('disabled', 'disabled')
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    schedules: [],
    status: null,
    error: null,
  },
  reducers: {
    cleanSchedule(state) {
      state.schedules = []
    },
    addSchedule(state, action) {
      state.schedules.push(action.payload)
    },
    deleteSchedule(state, action) {
      state.schedules = state.schedules.filter(schedule => schedule.id !== action.payload)
      success.fire({
        text: "Маршрут удален!",
      });
    },
    modifySchedule(state, action) {
      state.schedules = state.schedules.filter(schedule => schedule.id !== action.payload.id)
      state.schedules.push(action.payload)
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchSchedule.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [creatSchedule.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [editSchedule.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchSchedule.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.schedules = action.payload
    },
    [fetchSchedule.rejected]: setError,
    [creatSchedule.rejected]: setError,
    [editSchedule.rejected]: setError,
    [removeSchedule.rejected]: setError,
  }
})
export const { addSchedule, deleteSchedule, modifySchedule, cleanSchedule } = scheduleSlice.actions
export default scheduleSlice.reducer