import React, { useState, useEffect } from "react";

export default function Input({ id, nameLabel, value = '', check = false, cleanValue }) {

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (check) setChecked(true)
  }, [check])

  useEffect(() => {
    if (cleanValue) {
      setChecked(false)
    }
  }, [cleanValue])

  return (
    <>
      <input
        id={'day-' + id}
        value={value}
        type="checkbox"
        name="day"
        onChange={() => setChecked(!checked)}
        checked={checked}
      />
      <label htmlFor={'day-' + id}>{nameLabel}</label>
    </>
  )

}