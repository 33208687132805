import React, { useCallback, useEffect, useRef, useState } from "react";
import Menu from '../components/Menu/Menu';

import { Button, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import SelectSearch from '../components/UI/SelectSearch/SelectSearch'

import { useSelector, useDispatch } from 'react-redux';
import { fetchTrail } from '../store/trailSlice'
import { fetchSchedule } from '../store/scheduleSlice'

import TicketForm from '../components/AddTicketForm/TicketForm';
import ContacsForm from "../components/AddTicketForm/ContacsForm";

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';


const buttonBox = {
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '25px 0px 25px 0px'
  },
  p: {
    margin: '0px 0px 0px 10px'
  },
  selectBox: {
    display: 'flex',
    minWidth: '640px',
  },
  select: {
    marginRight: '20px',
    width: '100%',
  }
}

function Trail() {

  const dispatch = useDispatch()

  // Запрашиваю всё маршруты 
  useEffect(() => {
    dispatch(fetchTrail())
  }, [])
  const trails = useSelector(state => state.trails.trails)
  //-------------------------


  // Запрашиваю всё расписания которые есть у маршрута по id

  function getPageTrail({ id }) {
    // очищаю костылем 
    setTicket('')
    dispatch(fetchSchedule(id))
  }
  const schedules = useSelector(state => state.schedules.schedules)
  //--------------------------------------------------------


  const [scheduleArr, setScheduleArr] = useState([])
  // После выбора маршрута генерируем список расписаний 1
  useEffect(() => {
    // Если дата выбрана сегодня а время в билеете уже прошло убираем расписание
    const compareTime = (time) => {
      const date = new Date()
      const sendingtimeSeconds = (new Date(whatDate.date2 + '-' + time + ' ' + 'GMT+0300').getTime() / 1000).toFixed()// выбранная дата из колендаря и время из белета 
      const nowDate = (date.getTime() / 1000).toFixed() //дата и время на сейчас в сукундах
      if (sendingtimeSeconds > nowDate) return time
      else return false
    }
    const schedule = schedules.filter(t => t.sendingtime == compareTime(t.sendingtime)).map(name => {
      return {
        value: name.wherefrom + " ( отп." + name.sendingtime + " " + name.arrivalstop + " ) - " + name.where + " ( приб." + name.arrivaltime + " " + name.stoppingsending + " ) - " + name.price + "руб",
        id: name.id
      }
    })
    setScheduleArr(schedule)
  }, [schedules])
  // --------------------------------------------------

  const [selectedSchedule, setSelectedSchedule] = useState('')

  function getSchedule({ id }) {
    const schedule = schedules.find(idx => idx.id === id)
    setTicket(schedule)
    setSelectedSchedule(schedule)

  }

  // добавляем билеты билетов
  const [ticketForm, setTicketForm] = useState([])

  const addTicket = () => {
    setTicketForm([...ticketForm, {
      id: ticketForm.length
    }])
  }

  const [ticket, setTicket] = useState('')
  const [ticketsSum, setTicketsSum] = useState(0)

  const [isDisabledAddTicked, setIsDisabledAddTicked] = useState(true)

  useEffect(() => {
    if (ticket) setIsDisabledAddTicked(false)
    else setIsDisabledAddTicked(true)
  }, [ticket])

  const removeTicket = (id) => {
    const removedTicket = ticketForm.filter(t => t.id !== id)
    setTicketForm(removedTicket)
  }

  const [isDisabled, setIsDisabled] = useState(true)
  const [isDisabledCalendar, setIsDisabledCalendar] = useState(false)

  //Блакируем календарь? маршруты и расписание если добавлены билеты
  useEffect(() => {
    ticketForm.length > 0 ? setIsDisabled(true) : setIsDisabled(false)
    ticketForm.length > 0 ? setIsDisabledCalendar(true) : setIsDisabledCalendar(false)

  }, [ticketForm])

  // Выберете дату---------------------------
  const refGetDate = useRef('')
  const calendarOptions = {
    autoClose: true,
    view: "days",
    minView: "months",
    dateFormat: "dd.MM.yyyy",
    multipleDatesSeparator: " - ",
    buttons: ['clear'],
    minDate: new Date(),
    onHide: (isFinished) => {
      isFinished && setGetDate()
    }
  }

  useEffect(() => { new AirDatepicker('#getDate', calendarOptions) }, [])

  const [whatDate, setWhatDate] = useState({})
  const [getNameTimeTrails, setGetNameTimeTrails] = useState([])



  useEffect(() => {
    getNameTimeTrails.length > 0 ? setIsDisabled(false) : setIsDisabled(true)
  }, [getNameTimeTrails])

  function comparisonDate(dateStart, dateFinish, trailDay, { date, day }) {
    const compareDays = trailDay.find(d => d === day)
    const getDate = (d, gtm) => { return (new Date(d[1] + "-" + d[0] + "-" + d[2] + gtm).getTime() / 1000).toFixed() }
    if (date >= getDate(dateStart.split('.'), 'GMT+3:00') && date <= getDate(dateFinish.split('.'), 'GMT-21:00') && compareDays) return true
    else return false
  }

  useEffect(() => {
    if (Object.keys(whatDate).length > 0 && ticket) setIsDisabledAddTicked(false)
    else {
      setIsDisabledAddTicked(true)
      setScheduleArr([])
    }
    const getTrails = trails.filter(name => comparisonDate(name.start, name.finish, name.day, whatDate)).map(name => {
      // возвращаем ( в Москву с 01.04.2022 по 01.05.2022, ДНИ: понедельник, вторник,)
      let day = ''
      for (const iterator of name.day) day += ' ' + iterator + ', '
      return {
        id: name.id,
        value: name.name + " с " + name.start + " по " + name.finish + ", ДНИ: " + day,
      }
    })
    setGetNameTimeTrails(getTrails)
  }, [whatDate])

  const [selectDate, setSelectDate] = useState('')

  // Получаем дату в секундах и дни
  const setGetDate = useCallback(() => {
    setTicket('')
    setSelectDate(refGetDate.current.value)
    const val = refGetDate.current.value.split('.')
    const selectDate = new Date(val[1] + "-" + val[0] + "-" + val[2] + 'UTC-18:00')
    const days = ["воскресенье", "понедельник", "вторник", "среда",
      "четверг", "пятница", "суббота"]
    const whatDateSec = (selectDate.getTime() / 1000).toFixed()
    const whatDay = days[selectDate.getDay()]
    if (whatDay) setWhatDate({ day: whatDay, date: whatDateSec, date2: val[1] + "-" + val[0] + "-" + val[2] })
    else setWhatDate({})
  }, [])
  //-----------------------------------------
  const getInputPrice = useRef('')
  // Рескция на изменения checkbox детский
  const [child, setChild] = useState('')
  function childrenWatch(params) {
    setChild(params);
  }
  // Сумируем цену билетов
  useEffect(() => {
    const sum = [...getInputPrice.current.querySelectorAll("[name='price']")].reduce((akum, el) => akum + +el.value.replace(/[A-z]|[А-я]|[:]/g, ''), 0)
    setTicketsSum(sum);
  }, [ticketForm, child])

  return (
    <section className="pages">
      <Menu />

      <Container ref={getInputPrice}>
        <h1>ДОБАВИТЬ БРОНЬ</h1>
        <Row>
          <Col lg="12" className='mb-3'>
            <div className="input-style">
              <label>Выберете дату</label>
              <input readOnly ref={refGetDate} id="getDate" autoComplete="off" disabled={isDisabledCalendar} />
            </div>
          </Col>
          <Col lg="6" className='mb-3 select-trail'>
            <div style={buttonBox.select} >
              <SelectSearch search={true} placeholder={"Выберите маршрут"} noOptionsMessage={'На эту дату маршрутов нет!'} options={getNameTimeTrails} onChange={getPageTrail} isDisabled={isDisabled} />
            </div>
          </Col>
          <Col lg="6" className='mb-3 select-trail'>
            <div style={buttonBox.select}>
              <SelectSearch search={true} placeholder="Выберите расписание" noOptionsMessage={'По этому маршруту расписания отсутствуют!'} onChange={getSchedule} options={scheduleArr} isDisabled={isDisabled} />
            </div>
          </Col>
          <Col lg="6" className='mb-3'>
            <div style={buttonBox.buttonBox} className="button-box">
              <Button disabled={isDisabledAddTicked} onClick={addTicket}> + </Button>
              <p style={buttonBox.p}>Количество билетов: {ticketForm.length}</p>
            </div>
          </Col>

          {ticketForm.map(form =>
            <Col lg="12" className='mb-3' key={form.id}>
              <TicketForm idx={form.id} {...ticket} idTicket={form.id} removeTicket={removeTicket} childrenWatch={childrenWatch} />
            </Col>
          )}

        </Row>
      </Container>
      <Container>
        {ticketForm.length > 0 ? <Row className="justify-content-end">
          <Col lg="4" className="justify-content-end d-flex">
            <h2>К оплате: {ticketsSum} BYN</h2>
          </Col>
        </Row> : ''}
      </Container>
      <Container className="edit-box">
        {ticketForm.length > 0 ? <ContacsForm selectDate={selectDate} ticketsPriceSum={ticketsSum} selectedSchedule={selectedSchedule} /> : ''}
      </Container>

    </section >
  );
};

export default Trail;