import React, { useEffect } from "react";
import Menu from '../components/Menu/Menu';

import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { CreateTrailForm } from '../components/TrialForms/CreateTrailForm'
import { EditTrailForm } from '../components/TrialForms/EditTrailForm'

import { useSelector, useDispatch } from 'react-redux';

import { cleanSchedule } from '../store/scheduleSlice'

function Trail() {
  const dispatch = useDispatch()
  const { status, error } = useSelector(state => state.trails)

  useEffect(() => {
    dispatch(cleanSchedule())
  }, [dispatch])

  return (
    <section className="pages">
      <Menu />
      <Container>
        <h1>Маршруты</h1>
        <CreateTrailForm />
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <h2>Loading...</h2>}
        {error && <h2>{error}</h2>}
        <EditTrailForm />
      </Container>
    </section >
  );
};

export default Trail;