import React, { useEffect } from "react";
import Menu from '../components/Menu/Menu';

import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { fetchOneTrail } from '../store/trailSlice'

import { CreateScheduleForm } from '../components/ScheduleForm/CreateScheduleForm'
import { EditScheduleForm } from '../components/ScheduleForm/EditScheduleForm'
import { fetchSchedule } from '../store/scheduleSlice'

function TrailIdPage() {
  const dispatch = useDispatch()
  const trail = useSelector(state => state.trails.trail)
  const { status, error } = useSelector(state => state.schedules)
  const params = useParams()
  useEffect(() => {
    dispatch(fetchOneTrail(params.id))
  }, [])
  useEffect(() => {
    dispatch(fetchSchedule(params.id))
  }, [])
  return (
    <section className="pages">
      <Menu />
      <Container>
        <h1>{trail.name}</h1>
        <CreateScheduleForm trail={trail} id={params.id} />
      </Container>
      <Container className="edit-box">
        {status === 'loading' && <h2>Loading...</h2>}
        {error && <h2>{error}</h2>}
        <EditScheduleForm trail={trail} />
      </Container>
    </section >
  );
};

export default TrailIdPage;