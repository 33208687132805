import React from "react";
import Input from '../UI/Input';

import { alertErr, success } from '../UI/alert'
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './ScheduleFormStyle.sass'

import { useDispatch } from 'react-redux';

import { creatSchedule } from '../../store/scheduleSlice'

const calendarOptions = {
  onlyTimepicker: true,
  autoClose: true,
  buttons: ['clear'],
  timepicker: true,
  minHours: 0,
  maxHours: 24,
  minutesStep: 1
}
const inpetTime = {
  width: 130 + 'px'
}

function CreateScheduleForm({ trail, id }) {
  const dispatch = useDispatch()
  const sendCreatSchedule = (e, idx) => {

    e.preventDefault()
    const object = {}
    const formData = new FormData(e.target)
    formData.forEach(function (value, key) {
      if (value !== '') object[key] = value
    });

    if (Object.keys(object).length !== 8) {
      alertErr.fire({
        text: 'Нужно заполнить все поля!',
      })
      return
    }

    dispatch(creatSchedule({ object, idx }))

    success.fire({
      text: "Расписание добавлено!",
    });

  }

  return (
    <Form onSubmit={(e) => sendCreatSchedule(e, id)}>
      <Row className="add-booking">
        <Col lg="4" className='mb-0 mt-3'>
          <Row>
            <Col lg="6" className='mb-3'>
              <Input title="Откуда" name="wherefrom" />
            </Col>
            <Col lg="6" className='mb-3'>
              <Input title="Время отправки" style={inpetTime} calendarOptions={calendarOptions} className={"time-1-" + trail.id} name="sendingtime" />
            </Col>
            <Col lg="12" className='mb-3'>
              <Input title="Остановка отправки" name="stoppingsending" />
            </Col>
          </Row>
        </Col>
        <Col lg="4" className='mb-0 mt-3'>
          <Row>
            <Col lg="6" className='mb-3 mt-0'>
              <Input title="Куда" name="where" />
            </Col>
            <Col lg="6" className='mb-3 mt-0'>
              <Input title="Время прибытия" style={inpetTime} calendarOptions={calendarOptions} className={"time-2-" + trail.id} name="arrivaltime" />
            </Col>
            <Col lg="12" className='mb-3 mt-0'>
              <Input title="Остановка прибытия" name="arrivalstop" />
            </Col>
          </Row>
        </Col>
        <Col lg="4" className='mb-0 mt-3'>
          <Row>
            <Col lg="4" className='mb-3 mt-0'>
              <Input title="Цена" style={inpetTime} name="price" type="number" />
            </Col>
            <Col lg="4" className='mb-3 mt-0'>
              <Input title="Время в пути" style={inpetTime} name="traveltime" />
            </Col>
            <Col lg="4" className='mt-4 mb-5'>
              <Button className="btn-primary" type="submit"> Добавить </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export { CreateScheduleForm }
