import React, { useState, useEffect, useRef } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from '../components/Menu/Menu';
import { useSelector, useDispatch } from 'react-redux'
import { fetchOptions, editOptions } from '../store/optionsSlice'
const inputStyle = {
  input: {
    maxWidth: '100%'
  },
  button: {
    marginTop: '25px'
  }
}

const Options = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchOptions())
  }, [dispatch])
  const options = useSelector(state => state.options.options)
  const salesInput = useRef('')
  function saveSales() {
    const sales = salesInput.current.value
    const idx = 1
    dispatch(editOptions({ sales, idx }));
  }
  const [val, setVal] = useState(0)
  useEffect(() => {
    if (options.childrensales) setVal(options.childrensales)
  }, [options])

  function onInput(params) {
    if (params >= 0 && params <= 100) setVal(params)
  }
  return (
    <section className="pages">
      <Menu />
      <Container>
        <h1>Настройки</h1>
        <Row>
          <Col lg="3" md="3">
            <div className="input-style">
              <label>Скидка на детский билет в %</label>
              <input ref={salesInput} style={inputStyle.input} type="number" value={val} onChange={(e) => onInput(e.target.value)} />
            </div>
          </Col>
          <Col lg="3" md="3">
            <Button style={inputStyle.button} onClick={saveSales}>Сохранить</Button>
          </Col>
        </Row>
      </Container>
    </section >
  );
};

export default Options;