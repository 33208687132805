import React, { useEffect, useRef, useState } from "react";
import Menu from '../components/Menu/Menu';

import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'

import { fetchTicketOne, cleanTicket, deleteTicket, editTicket, addTicket } from '../store/ticketSlice'
import { alertErr } from '../components/UI/alert'

import BookingTicketForm from '../components/BookingForm/BookingTicketForm'
import BookingContacsForm from '../components/BookingForm/BookingContacsForm'
const buttonBox = {
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '25px 0px 25px 0px'
  },
  p: {
    margin: '0px 0px 0px 10px'
  },
}
function TrailIdPage() {
  const { status, error, ticket } = useSelector(state => state.tickets)
  const dispatch = useDispatch()
  const params = useParams()
  useEffect(() => {
    dispatch(fetchTicketOne(params.id))
    dispatch(cleanTicket())
  }, [dispatch])



  const { idx, id, messages, tickets, contacts, schedule } = ticket

  function getTickets() {
    const ticketForm = getInputPrice.current.querySelectorAll(".ticket-form")
    const arr = [...ticketForm].map(elm => elm.id);
    const ticketsArr = []
    arr.forEach(id => {
      const el = document.querySelectorAll(`#${id} input`)
      const ar = {
        [id]: new Map()
      }
      el.forEach(e => {
        if (e.checked) ar[id].set(e.name, e.value)
        if (e.type !== 'checkbox') ar[id].set(e.name, e.value)
        else ar[id].set('id', e.id.split('-')[1])
      })
      ticketsArr.push(Object.fromEntries(ar[id]))
    })
    return ticketsArr
  }

  const removeTicket = (index) => {
    if (getTickets().length < 2) {
      alertErr.fire({
        text: 'Бронь не может быть без билетов!',
      })
      return
    }
    const newArrTickets = tickets.filter(t => t.id !== index)

    const newArrData = {
      id: id,
      contacts: contacts,
      idx: idx,
      messages: messages,
      schedule: schedule,
      tickets: newArrTickets
    }

    dispatch(deleteTicket(newArrData))
  }

  function saveTickets(ticketContacts) {
    const error = new Set()
    const checkingForEmpti = (object) => {
      for (const key in object) {
        if (object[key] === '') error.add('err')
      }
    }
    checkingForEmpti(ticketContacts)
    getTickets().forEach(object => {
      checkingForEmpti(object)
    })
    if (error.size > 0) {
      alertErr.fire({
        text: 'Должны быть заполнены все поля!',
      })
      return
    }
    const object = {
      id: id,
      contacts: ticketContacts,
      idx: idx,
      messages: messages,
      schedule: schedule,
      tickets: getTickets()
    }

    dispatch(editTicket({ object, idx }))
  }
  const addOneTicket = () => {
    const ticketLastId = +tickets[tickets.length - 1].id + 1
    const newArrData = {
      id: id,
      contacts: contacts,
      idx: idx,
      messages: messages,
      schedule: schedule,
      tickets: [
        ...getTickets(),
        {
          id: String(ticketLastId),
          price: tickets[tickets.length - 1].price,
          Гражданство: "",
          Дата_рождения: "",
          Имя: "",
          Код: "",
          Номер_документа: "",
          Отчество: "",
          Пол: "",
          Телефон: "",
          Тип_документа: "",
          Фамилия: "",
        }
      ]
    }

    dispatch(addTicket(newArrData))
  }
  const [ticketsSum, setTicketsSum] = useState(0)
  const getInputPrice = useRef('')
  // Рескция на изменения checkbox детский
  const [child, setChild] = useState('')
  function childrenWatch(params) {
    setChild(params);
  }
  // Сумируем цену билетов
  useEffect(() => {
    const sum = [...getInputPrice.current.querySelectorAll("[name='price']")].reduce((akum, el) => akum + +el.value.replace(/[A-z]|[А-я]|[:]/g, ''), 0)
    setTicketsSum(sum)
  }, [ticket, child])
  useEffect(() => {
    if (!tickets) return
    const sumLoad = tickets.reduce((akum, el) => akum + +el.price.replace(/[A-z]|[А-я]|[:]/g, ''), 0)
    setTicketsSum(sumLoad)
  }, [tickets])
  console.log(messages);
  return (
    <section className="pages">
      <Menu />
      <Container>
        <h1>Бронь № {id} на {messages?.trailDate}</h1>
        <p>{messages?.trailFrom}</p>
        <p>{messages?.trailTo}</p>
        <p>Время в пути: {messages?.timeWay}</p>
        <div style={buttonBox.buttonBox} className="button-box">
          <Button onClick={addOneTicket}> + </Button>
          <p style={buttonBox.p}>Количество билетов: {tickets?.length}</p>
        </div>
      </Container>
      <Container ref={getInputPrice}>
        <Row>

          {status === 'loading' && <h2>Loading...</h2>}
          {error && <h2>{error}</h2>}
          {tickets ?
            tickets.map((t, i) =>
              <Col lg="12" md="12" className="mb-4" key={t.id}>
                <BookingTicketForm removeTicket={removeTicket} tickets={t} idx={i} idTicket={t.id} schedule={schedule} childrenWatch={childrenWatch} />
              </Col>
            )
            : ''
          }

        </Row>
      </Container>
      <Container>
        <Row className="justify-content-end">
          <Col lg="4" className="justify-content-end d-flex">
            <h2>К оплате: {ticketsSum} BYN</h2>
          </Col>
        </Row>
      </Container>
      <Container>
        {tickets ? <BookingContacsForm contacts={contacts} saveTickets={saveTickets} /> : ''}
      </Container>
    </section >
  );
};

export default TrailIdPage;