import React, { useState, useContext } from 'react'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthContext } from '../context'

const Auth = () => {
  const { isAuth, setIsAuth } = useContext(AuthContext)

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);
    fetch("https://booking.gomel-moskva.by/core/getAuth.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result) {
          alert('Не верный пароль!')
          return
        }
        setIsAuth(result)
        // document.cookie = `stolica-auth=${result}; max-age=172800`;
      }).catch((error) => {
        console.log(error);
      });
  }
  return (
    <Container>
      <Row style={{ marginTop: '10%' }} className="justify-content-center align-items-center">
        <Col sm="8" md="6" lg="4">
          <Form onSubmit={handleSubmit}>

            <Form.Group className="mb-3">
              <Form.Label>Логин</Form.Label>
              <Form.Control value={login} onChange={(e) => setLogin(e.target.value)} type="text" placeholder="Логин" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Пароль</Form.Label>
              <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Пароль" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Войти
            </Button>

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Auth;