import React, { useEffect } from 'react';
import Input from '../UI/Input';
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { fetchTicket, removeBooking } from '../../store/ticketSlice'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
const style = {
  h3: {
    marginTop: '15px'
  },
  button: {
    width: '100%'
  },
  p: {
    margin: '15px 0 0 0'
  },
  row: {
    margin: '10px 0px',
    background: '#fff',
  }
}
function BookingForm({ search }) {
  const dispatch = useDispatch()
  const tickets = useSelector(state => state.tickets.tickets)
  // console.log(tickets);
  let reverse = [...tickets].reverse()
  const newSearch = reverse.filter(el => {
    if (el.id.includes(search)) return el
    if (el.contacts['Имя'].includes(search)) return el
    if (el.messages.trailDate.includes(search)) return el
    if ((el.contacts['Код'] + el.contacts['Телефон']).replace(/( )|[()]/g, '').includes(search)) return el
    if (el.contacts['email'].includes(search)) return el
  })
  useEffect(() => {
    dispatch(fetchTicket())
  }, [dispatch])

  function deleteBooking(idx) {
    dispatch(removeBooking(idx))
  }

  return (
    <TransitionGroup>
      {newSearch.map((elm, i) =>
        <CSSTransition
          key={elm.idx}
          timeout={500}
          classNames="formtrail"
        >
          <Container style={style.row} className="container-form">
            <span onClick={() => deleteBooking(elm.idx)} className="close-button">&times;</span>
            <Row className='align-items-end' >
              <p style={style.p}>Бронь № <b>{elm.id} на {elm.messages.trailDate} </b> | {elm.messages.trailFrom} - {elm.messages.trailTo}</p>
              <Col lg="3" className='mb-3 mt-3'>
                <Input title="Имя" name='Имя' className={'booking-input'} value={elm.contacts.Имя} />
              </Col>
              <Col lg="3" className='mb-3 mt-3'>
                <Input title="Телефон" name='Имя' className={'booking-input'} value={elm.contacts['Код'] + ' ' + elm.contacts['Телефон']} />
              </Col>
              <Col lg="3" className='mb-3 mt-3'>
                <Input title="Email" type="email" name='email' className={'booking-input'} value={elm.contacts.email} />
              </Col>
              <Col lg="3" className='mb-3 mt-4'>
                <Link style={style.button} className="btn btn-danger" variant="danger" to={'/booking/' + elm.idx}>Подробнее</Link>
              </Col>
            </Row>
          </Container>
        </CSSTransition>
      )}
    </TransitionGroup >
  );
}

export default BookingForm;