import React from "react";
import Menu from '../components/Menu/Menu';

import { useNavigate, Navigate, Routes, Route } from 'react-router-dom'

import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useSelector } from 'react-redux'

function TrailIdPage() {
  const navigate = useNavigate()
  const ticket = useSelector(state => state.tickets.ticket)

  return (
    <section className="pages">
      <Menu />
      <Container>
        {ticket ?
          <>
            <h1>{ticket.messages?.success}</h1>
            <h4></h4>
            <p>Бронь № <b>{ticket.id}</b></p>
            <p>Дата: <b>{ticket.messages?.trailDate}</b></p>
            <p><b>{ticket.messages?.trailFrom}</b></p>
            <p><b>{ticket.messages?.trailTo}</b></p>
            <p>Время в пути: <b>{ticket.messages?.timeWay}</b></p>
            <p>Стоимость итого: <b>{ticket.messages?.price} руб.</b></p>
            <Button onClick={() => navigate(-1)}>Вернуться назад</Button>
          </>
          :
          <Routes>
            <Route path='*' element={<Navigate to="/add-booking" replace />} />
          </Routes>
        }
      </Container>
    </section >
  );
};

export default TrailIdPage;