import Trail from '../pages/Trail'
import Schedule from '../pages/Schedule'
import Booking from '../pages/Booking'
import BookingTicket from '../pages/BookingTicket'
import AddTicket from '../pages/AddTicket'
import SuccessAddTicket from '../pages/SuccessAddTicket'
import Options from '../pages/Options'
import Login from '../pages/Login'

export const privetRoutes = [
  { path: '/trail', element: Trail, exact: true },
  { path: '/trail/:id', element: Schedule, exact: true },
  {path: '/booking/:id', element: BookingTicket, exact: true},
  {path: '/booking', element: Booking, exact: true},
  { path: '/add-booking', element: AddTicket, exact: true },
  { path: '/success-add-ticket', element: SuccessAddTicket, exact: true },
  { path: '/options', element: Options, exact: true },
]

export const publicRoutes = [
  { path: '/login', element: Login, exact: true },
]