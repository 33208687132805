
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Container, Navbar, Offcanvas, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.sass';

function Menu() {

  return (
    <Navbar className="align-items-start" variant="light" bg="white" expand={false} >
      <Container fluid>
        <Navbar.Toggle aria-controls="offcanvasNavbar" fixed="top" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          fixed="top"
        >
          <Offcanvas.Header closeButton >
            <Offcanvas.Title id="offcanvasNavbarLabel">МЕНЮ</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-start flex-grow-1 pe-3">
              <NavLink className="btn btn-primary" to="/trail">Маршруты</NavLink>
              <NavLink className="btn btn-primary" to="/add-booking">Добавить бронь</NavLink>
              <NavLink className="btn btn-primary" to="/booking">Бронь</NavLink>
              <NavLink className="btn btn-primary" to="/options">Настройки</NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Menu;
