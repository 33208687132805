import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { alertErr, success } from '../components/UI/alert'

export const fetchOptions = createAsyncThunk(
  'options/fetchOptions',
  async function (_, { rejectWithValue }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/options`, {
        method: 'GET',
      })
      if (!response.ok) throw new Error('Server Error!');
      const data = await response.json()
      return data[1]
    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Не удалось получить данные.',
      })
      return rejectWithValue(error.message)
    }
  }
)

export const editOptions = createAsyncThunk(
  'options/editOptions',
  async function ({sales,idx}, { rejectWithValue, dispatch }) {
    try {
      const response = await fetch(`https://booking.gomel-moskva.by/core/options/${idx}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(+sales),
      })
      if (!response.ok) throw new Error('Can\'t edit task. Server Error!');
      const data = await response.json()
      dispatch(modifyOptions(data))

    } catch (error) {
      alertErr.fire({
        text: 'Ошибка сервера! Объект не удалось сохранить.',
      })
      return rejectWithValue(error.message)
    }
  }
)
const setError = (state, action) => {
  state.status = 'rejected'
  state.error = action.payload
}

const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    options: '',
    status: null,
    error: null,
  },
  reducers: {
    modifyOptions(state, action) {
      state.options = action.payload
      success.fire({
        text: "Изменение сохранено!",
      });
    },
  },
  extraReducers: {
    [fetchOptions.pending]: (state) => {
      state.status = 'loading'
      state.error = null
    },
    [editOptions.pending]: (state) => {
      state.status = null
      state.error = null
    },
    [fetchOptions.fulfilled]: (state, action) => {
      state.status = 'resolved'
      state.options = action.payload
    },
    [fetchOptions.rejected]: setError,
    [editOptions.rejected]: setError,
  }
})
const { modifyOptions } = optionsSlice.actions
export default optionsSlice.reducer