import React, { useRef, useState } from 'react';

import Input from '../UI/Input';
import InputMask from 'react-input-mask';
import SelectSearch from '../UI/SelectSearch/SelectSearch'
import { Button, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const style = {
  h3: {
    marginTop: '15px'
  },
  button: {
    width: '100%'
  }
}
const inputPhoneStyle = {
  inputPhoneBox: {
    display: 'flex',
    alignItems: 'center'
  },
  p: {
    margin: '0 5px 0 0'
  },
  inputPhone: {
    marginLeft: '8px'
  }
}

function BookingContacsForm({ contacts, saveTickets }) {

  const [val, setVal] = useState(contacts['Код'],);

  function getSelect({ value }) {
    setVal(value)
  }
  const contactsForm = useRef('')
  function getInputs() {
    const inputs = contactsForm.current.querySelectorAll(`input`)
    const contacts = new Map()
    inputs.forEach(el => {
      contacts.set(el.name, el.value)
    })
    saveTickets(Object.fromEntries(contacts));
  }
  return (
    <Container ref={contactsForm} className="edit-box">
      <h3 style={style.h3}>Контактные данные</h3>
      <Row className='align-items-end' >
        <Col lg="3" className='mb-3 mt-3'>
          <Input title="Имя" name={'Имя'} value={contacts['Имя']} />
        </Col>
        <Col lg="3" className='mb-3 mt-3'>
          <div className="input-style">
            <label>Телефон</label>
            <div style={inputPhoneStyle.inputPhoneBox} className="input-phone-box">
              <p style={inputPhoneStyle.p}>+</p>
              <SelectSearch defaultValue={{ value: contacts['Код'], name: 'Код' }} onChange={getSelect}
                options={[{ value: '+375', name: 'Код' }, { value: '+7', name: 'Код' }]}
              />
              {val === '+375'
                ? <InputMask name={'Телефон'} style={inputPhoneStyle.inputPhone} mask="(99) 999 99 99" maskChar="_" placeholder="(__) ___ __ __" key="1" defaultValue={contacts['Телефон']} />
                : <InputMask name={'Телефон'} style={inputPhoneStyle.inputPhone} mask="(999) 999 99 99" maskChar="_" placeholder="(___) ___ __ __" key="2" defaultValue={contacts['Телефон']} />
              }
            </div>
          </div>
        </Col>
        <Col lg="3" className='mb-3 mt-3'>
          <Input title="Email" type="email" name={'email'} value={contacts['email']} />
        </Col>
        <Col lg="3" className='mb-3 mt-4'>
          <Button style={style.button} onClick={getInputs}>Сохранить</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default BookingContacsForm;