import React from "react";
import Input from '../UI/Input';
import { useSelector, useDispatch } from 'react-redux'

import { removeSchedule, editSchedule } from '../../store/scheduleSlice'

import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './ScheduleFormStyle.sass'
import { TransitionGroup, CSSTransition } from 'react-transition-group';


const inpetTime = {
  width: 130 + 'px'
}

function EditScheduleForm({ trail }) {
  const dispatch = useDispatch()

  const schedules = useSelector(state => state.schedules.schedules)
  let reverse = [...schedules].reverse()

  function deleteSchedule(id) {
    dispatch(removeSchedule(id))
  }

  const sendEditSchedule = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const object = {}
    let idx = ''
    formData.forEach((value, key) => {
      if (key === 'id') idx = value
      else object[key] = value
    });
    dispatch(editSchedule({ object, idx }))
  }

  function getFormData(id) {
    const form = document.getElementById(id)
    const button = form.querySelector('button')
    const formData = new FormData(form)

    const object = {
      id: ''
    }

    formData.forEach((value, key) => {
      if (key === 'day') object.day.push(value)
      else if (key === 'id') object.id = value
      else object[key] = value
    });

    const trail = schedules.filter(trail => trail.id === id)

    if (JSON.stringify(...trail) !== JSON.stringify(object)) {
      button.removeAttribute('disabled')
    } else {
      button.setAttribute('disabled', 'disabled')
    }
  }

  function clickForm(id) {
    getFormData(id)
  }
  const calendarOptions = (id) => {
    return {
      onlyTimepicker: true,
      autoClose: true,
      buttons: ['clear'],
      timepicker: true,
      minHours: 0,
      maxHours: 24,
      minutesStep: 1,
      onHide: (isFinished) => {
        isFinished && getFormData(id)
      }
    }
  }
  return (
    <TransitionGroup>
      {reverse.map((elm, i) =>
        <CSSTransition
          key={elm.id}
          timeout={500}
          classNames="formtrail"
        >
          <Container>
            <Form id={elm.id} onSubmit={sendEditSchedule} onKeyUp={() => clickForm(elm.id)} onClick={() => clickForm(elm.id)}>
              <Row className="edit-booking">
                <Col lg="4" className='mb-0 mt-3'>
                  <span onClick={() => deleteSchedule(elm.id)} className="close-button">&times;</span>
                  <Row>
                    <input type="hidden" name="id" value={elm.id} />
                    <Col lg="6" className='mb-3'>
                      <Input title="Откуда" name="wherefrom" value={elm.wherefrom} />
                    </Col>
                    <Col lg="6" className='mb-3'>
                      <Input title="Время отправки" style={inpetTime} calendarOptions={calendarOptions(elm.id)} className={"time-1-" + trail.id + '-' + i} name="sendingtime" value={elm.sendingtime} />
                    </Col>
                    <Col lg="12" className='mb-3'>
                      <Input title="Остановка отправки" name="stoppingsending" value={elm.stoppingsending} />
                    </Col>
                  </Row>
                </Col>
                <Col lg="4" className='mb-0 mt-3'>
                  <Row>
                    <Col lg="6" className='mb-3 mt-0'>
                      <Input title="Куда" name="where" value={elm.where} />
                    </Col>
                    <Col lg="6" className='mb-3 mt-0'>
                      <Input title="Время прибытия" style={inpetTime} calendarOptions={calendarOptions(elm.id)} className={"time-2-" + trail.id + '-' + i} name="arrivaltime" value={elm.arrivaltime} />
                    </Col>
                    <Col lg="12" className='mb-3 mt-0'>
                      <Input title="Остановка прибытия" name="arrivalstop" value={elm.arrivalstop} />
                    </Col>
                  </Row>
                </Col>
                <Col lg="4" className='mb-0 mt-3'>
                  <Row>
                    <Col lg="4" className='mb-3 mt-0'>
                      <Input title="Цена" style={inpetTime} name="price" value={elm.price} type="number" />
                    </Col>
                    <Col lg="4" className='mb-3 mt-0'>
                      <Input title="Время в пути" style={inpetTime} name="traveltime" value={elm.traveltime} />
                    </Col>
                    <Col lg="4" className='mt-4 mb-5'>
                      <Button className="btn-success" type="submit" disabled> Сохранить </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>

        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

export { EditScheduleForm }
