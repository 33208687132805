import React, { useEffect } from 'react';
import Input from '../UI/Input';

import { useSelector, useDispatch } from 'react-redux'

import SelectDayEdit from '../UI/SelectDayEdit';

import { editTrail, removeTrail, fetchTrail } from '../../store/trailSlice'

import { Link } from 'react-router-dom'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './TrailFormsStyle.sass';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

function EditTrailForm() {
  const dispatch = useDispatch()
  const trails = useSelector(state => state.trails.trails)
  let reverse = [...trails].reverse()

  useEffect(() => {
    dispatch(fetchTrail())
  }, [dispatch])

  function deleteTrail(id) {
    dispatch(removeTrail(id))
  }

  const sendEditTrail = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const object = {
      day: []
    }
    let idx = ''
    formData.forEach((value, key) => {
      if (key === 'day') object.day.push(value)
      else if (key === 'id') idx = value
      else object[key] = value
    });
    dispatch(editTrail({ object, idx }))
  }

  function getFormData(id) {
    const form = document.getElementById(id)
    const button = form.querySelector('button')
    const formData = new FormData(form)

    const object = {
      id: '',
      day: []
    }

    formData.forEach((value, key) => {
      if (key === 'day') object.day.push(value)
      else if (key === 'id') object.id = value
      else object[key] = value
    });

    const trail = trails.filter(trail => trail.id === id)

    if (JSON.stringify(...trail) !== JSON.stringify(object)) {
      button.removeAttribute('disabled')
    } else {
      button.setAttribute('disabled', 'disabled')
    }
  }

  function clickForm(e, id) {
    if (e.target.name === "name") getFormData(id)
    if (e.target.type === "checkbox") getFormData(id)
  }

  const calendarOptions = (id) => {
    return {
      autoClose: true,
      view: "days",
      minView: "months",
      dateFormat: "dd.MM.yyyy",
      multipleDatesSeparator: " - ",
      buttons: ['clear'],
      onHide: (isFinished) => {
        isFinished && getFormData(id)
      }
    }
  }

  return (
    <TransitionGroup>
      {reverse.map((elm, i) =>
        <CSSTransition
          key={elm.id}
          timeout={500}
          classNames="formtrail"
        >
          <Container>
            <Form id={elm.id} onSubmit={sendEditTrail} onKeyUp={(e) => clickForm(e, elm.id)} onClick={(e) => clickForm(e, elm.id)}>
              <Row className="container-form">
                <Col lg="12">
                  <span onClick={() => deleteTrail(elm.id)} className="close-button">&times;</span>
                  <Row className="add-trail-row">
                    <input type="hidden" name="id" value={elm.id} />
                    <Col lg="3" className='mb-3 mt-4'>
                      <Input title="Название" name="name" value={elm.name} />
                    </Col>
                    <Col lg="3" className='mb-3 mt-4'>
                      <Input calendarOptions={calendarOptions(elm.id)} className={'calendar-1-' + elm.id} title="Начало" name="start" value={elm.start} />
                    </Col>
                    <Col lg="3" className='mb-3 mt-4'>
                      <Input calendarOptions={calendarOptions(elm.id)} className={'calendar-2-' + elm.id} title="Конец" name="finish" value={elm.finish} />
                    </Col>
                    <Col lg="3" className='mb-3 mt-4'>
                      <SelectDayEdit days={elm.day} idx={elm.id + "-" + i} />
                    </Col>
                    <Col lg="4" className='mb-3 mt-0 but-box'>
                      <Button className="btn-success" type="submit" disabled> Сохранить </Button>
                      <Link className="btn btn-danger" variant="danger" to={'/trail/' + elm.id}>Подробнее</Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

export { EditTrailForm }
