import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { publicRoutes, privetRoutes } from '../routes'
import Layout from '../pages/Layout'
import { AuthContext } from '../context'
export function AppRouter() {
  const { isAuth } = useContext(AuthContext)
  return (
    isAuth
      ?
      <Routes>
        <Route element={<Layout />}>
          {privetRoutes.map(route =>
            <Route
              path={route.path}
              element={<route.element />}
              exact={route.exact}
              key={route.path}
            />
          )}
          <Route path='*' element={<Navigate to="/trail" replace />} />
        </Route>
      </Routes>
      :
      <Routes>
        <Route element={<Layout />}>
          {publicRoutes.map(route =>
            <Route
              path={route.path}
              element={<route.element />}
              exact={route.exact}
              key={route.path}
            />
          )}
          <Route path='*' element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
  );
}
